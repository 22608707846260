<template>
  <div>
    <navbar-top left="back"
                middle="counter"
                right="flag"
                :data-right="dataRight"
                @cl="back"
                @cr="toggleFlag"
                @cra="switchTables">
      <p class="text-xl text-white">{{ currentCount }}/{{ totalCount }}</p>
    </navbar-top>

    <div class="fixed inset-0 py-14 overflow-y-auto"
         id="main"
         style="-webkit-overflow-scrolling: touch;">
      <div v-if="dataLoaded"
           class="wrapper p-3">
        <div v-if="!!task" class="mx-auto mb-6">
          <e-t-b v-for="block in taskBlocks"
                 :key="block.key"
                 :content="block.content"
                 :mod="block.mod" />
          <hr class="my-4">
          <div style="margin-bottom: 40vw;">
            <label for="studentAnswer"
                   :class="currentStep === 'reflecting' ? 'text-red-800' : ''">Ответ:</label>
            <input v-if="currentStep === 'answering'"
                   v-model="studentAnswer"
                   type="text"
                   :inputmode="task.kind === 'term' ? '' : 'numeric'"
                   :class="task.kind === 'term' ? 'w-40' : 'w-28'"
                   id="studentAnswer"
                   class="ml-1 px-1 h-8 bg-blue-50 rounded border-b-2 border-blue-800"
                   maxlength="30"
                   autocomplete="off">
            <p v-else class="inline ml-1 text-red-800">{{ studentAnswer }}</p>
            <p v-if="currentStep === 'reflecting'"
               class="italic text-green-600">Правильный ответ: {{ task.ans.join(' ИЛИ ') }}</p>
            <p class="mt-4 text-gray-400">id: {{ task.tid }}</p>
          </div>
        </div>
        <div v-else class="flex flex-col text-center items-center justify-center mx-auto">
          <h1 class="pt-8 text-2xl font-semibold">Все задания решены!</h1>
          <p class="mt-8 text-2xl font-light">Итоговый балл: {{score.got}}/{{score.max}} ({{ scorePercentage }}%)</p>
          <p class="mb-12 text-2xl text-gray-500 font-extralight italic">{{ scoreCommentary.text }}
            <span class="not-italic"> {{ scoreCommentary.emoji }}</span></p>
          <button v-if="score.got < score.max"
                  class="my-2 px-4 py-2 bg-blue-700 rounded text-2xl text-white font-light"
                  @click="loadIncorrects">Проработать ошибки</button>
          <button class="my-2 px-4 py-2 bg-blue-700 rounded text-2xl text-white font-light"
                  @click="back">Создать новый тест</button>
        </div>
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>

    <div v-if="tables.state > 0"
         class="table-wrapper">
      <img :src="tables.tables[tables.state]">
    </div>

    <navbar-bottom :state="currentStep"
                   :data="{ taskKind: !!task ? task.kind : '' }"
                   @key="handleKey"
                   @answer="revealAnswer"
                   @next="nextTask" />

    <keypress key-event="keyup" :multiple-keys="keyboard.enter" @success="revealAnswer" />
  </div>
</template>

<script>
import Keypress from 'vue-keypress';
import { nanoid } from 'nanoid';
import { mapState } from 'vuex';
import NavbarTop from '@/components/NavbarTop';
import NavbarBottom from '@/components/NavbarBottom';
import ETB from '@/components/EgetaskBlock.vue';
import MenuSpinner from '@/components/MenuSpinner.vue';
import { calculateReducedScore } from '@/assets/functions';
import { processChemistry } from '@/assets/formulasProcessing';


export default {
  components: { NavbarTop, NavbarBottom, ETB, MenuSpinner, Keypress },
  props: {
    mode: {
      type: String,
    },
    locations: {
      type: Array,
    },
    size: {
      type: String,
    },
  },
  data () {
    return {
      dataLoaded: false,
      lastClickAt: new Date(),
      currentStep: 'answering',
      studentAnswer: '',
      score: {
        max: 0,
        got: 0,
        incorrectTids: [],
      },
      tables: {
        state: 0,
        tables: {
          1: 'https://storage.yandexcloud.net/chemistry/task_imgs/PT.png',
          2: 'https://storage.yandexcloud.net/chemistry/task_imgs/ST.png',
        },
      },
      keyboard: {
        enter: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
        ],
      },
      cameFrom: '',
    };
  },
  computed: {
    ...mapState({
      task: state => state.egetasks.task,
      currentCount: state => state.egetasks.taskNum,
      totalCount: state => state.egetasks.tasksTotal,
    }),
    dataRight () {
      return {
        flagged: this.task ? this.task.flagged : false,
        addButton: !!this.task && this.task.subject === 'chem',
        subject: this.task ? this.task.subject : ''
      };
    },
    taskBlocks () {
      if (!this.task) return [];
      const strs = this.task.task.split('\n');
      const blocks = [];
      let buf = '';

      for (let str of strs) {
        if (/\d{1,3}_IMG_/.test(str) || /\d{1,3}_TAB_/.test(str)) {
          if (buf !== '') blocks.push({ content: buf, key: nanoid(8) });
          blocks.push({ content: str, key: nanoid(8) });
          buf = '';
        } else if (this.task && this.task.subject === 'chem') {
          const { processed, hasChain } = processChemistry(str);
          if (hasChain) {
            if (buf !== '') blocks.push({ content: buf, key: nanoid(8) });
            blocks.push({ content: processed, key: nanoid(8), mod: 'chain' });
            buf = '';
          } else buf += processed + '\n';
        } else buf += str + '\n';
      }
      if (buf !== '') blocks.push({ content: buf, key: nanoid(8) });

      return blocks;
    },
    scorePercentage () { return Math.floor(this.score.got / this.score.max * 100); },
    scoreCommentary () {
      if (this.scorePercentage > 98) return { text: 'Потрясающе!', emoji: '🎆' };
      if (this.scorePercentage > 94) return { text: 'Отличный результат!', emoji: '🔥' };
      if (this.scorePercentage > 89) return { text: 'Достойно!', emoji: '🏆' };
      if (this.scorePercentage > 81) return { text: 'Хороший результат!', emoji: '😌' };
      if (this.scorePercentage > 74) return { text: 'Ты можешь лучше!', emoji: '🧗‍♀' };
      return { text: '', emoji: '🗿' };
    },
  },
  created () {
    this.$store.commit('egetasks/initStore');
    this.$store.dispatch('egetasks/getTasks',
        { mode: this.mode, locations: this.locations, size: this.size })
        .then(() => {
          this.dataLoaded = true;
          this.cameFrom = this.task.subject;
        })
  },
  methods: {
    handleKey (value) {
      if (value === 'bs') this.studentAnswer = this.studentAnswer.slice(0, -1);
      else this.studentAnswer += value;
    },
    revealAnswer () {
      const maxScore = this.task.kind === 'term' ? 1 : 2;
      this.score.max += maxScore;

      if (this.task.ans.includes(this.studentAnswer.toLowerCase().trim())) {
        this.score.got += maxScore;
        this.$store.dispatch('egetasks/sendStat', { action: 'remove', field: 'incorrect', tid: this.task.tid });
        return this.nextTask();
      }

      this.score.incorrectTids.push(this.task.tid);
      this.score.got += calculateReducedScore(this.studentAnswer, this.task);
      this.$store.dispatch('egetasks/sendStat', { action: 'add', field: 'incorrect', tid: this.task.tid });
      this.currentStep = 'reflecting';
      return true;
    },
    async nextTask () {
      await this.$store.dispatch('egetasks/nextTask');
      this.studentAnswer = '';

      document.getElementById('main').scrollTop = 0;

      if (!this.task) this.currentStep = 'finished';
      else this.currentStep = 'answering';

      return true;
    },
    async toggleFlag () {
      if (!this.task) return false;
      const stat = {
        action: this.task.flagged ? 'remove' : 'add',
        field: 'flagged',
        tid: this.task.tid,
      };
      await this.$store.dispatch('egetasks/sendStat', stat);
      this.$store.commit('egetasks/toggleFlag');
      return true;
    },
    loadIncorrects () {
      this.dataLoaded = false;
      this.$store.commit('egetasks/initStore');
      this.$store.dispatch('egetasks/getTasksByTids', { tids: this.score.incorrectTids })
        .then(() => {
          this.score = { max: 0, got: 0, incorrectTids: [] };
          this.currentStep = 'answering';
          this.dataLoaded = true;
        })
    },
    switchTables () {
      if (this.tables.state > 1) return this.tables.state = 0;
      return this.tables.state += 1;
    },
    back () { this.$router.push(`/ege/create?subject=${this.cameFrom}`); },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: block;
  margin: auto;

  @media (max-width: 639px) {
    width: 100%;
  }

  @media (min-width: 640px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.table-wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  padding: 0.2rem 0;
  max-height: calc(100vh - 8rem);
  background: white;
  border-bottom: solid 0.25rem #1c4aca;
  box-shadow: 0 4px 8px 2px rgba(29,51,114,0.25);
  transform: translate(-50%, 3.5rem);

  @media (max-width: 639px) {
    width: 100%;
  }

  @media (min-width: 640px) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    width: 55%;
  }
}
</style>
