

function processChemistry (str) {
  let processed = `${str}`; // copying string
  const fRegex = /(#[^ ]+)/g;
  const rRegex = /(R\{.+})/g;
  const cRegex = /(CHAIN\{.+})/g;

  const hasFormula = fRegex.test(str);
  const hasReaction = rRegex.test(str);
  const hasChain = cRegex.test(str);

  if (hasFormula) processed = process(str, fRegex, processFormula)
  if (hasReaction) processed = process(str, rRegex, processReaction)
  if (hasChain) processed = process(str, cRegex, processChain)

  return { processed, hasChain };
}

function process (s, regex, processor) {
  const splittedStr = s.split(regex);
  for (let i = 0; i < splittedStr.length; i++) {
    if (regex.test(splittedStr[i]))
      splittedStr[i] = processor(splittedStr[i]);
  }
  return splittedStr.join('');
}

function processFormula (str) {
  const charArr = str.replace('#', '').split('');
  let formula = '';
  let superstring = false;

  for (const char of charArr) {
    if (superstring) {
      if (/[^0-9\-+]/.test(char)) {
        superstring = false;
        formula += char;
      }
      else if (char === '1') formula += '¹';
      else if (char === '2') formula += '²';
      else if (char === '3') formula += '³';
      else if (char === '4') formula += '⁴';
      else if (char === '5') formula += '⁵';
      else if (char === '6') formula += '⁶';
      else if (char === '7') formula += '⁷';
      else if (char === '8') formula += '⁸';
      else if (char === '9') formula += '⁹';
      else if (char === '0') formula += '⁰';
      else if (char === '+') formula += '⁺';
      else if (char === '-') formula += '⁻';
    } else {
      if (char === '^') superstring = true;
      else if (/[^0-9]/.test(char)) formula += char;
      else if (char === '1') formula += '₁';
      else if (char === '2') formula += '₂';
      else if (char === '3') formula += '₃';
      else if (char === '4') formula += '₄';
      else if (char === '5') formula += '₅';
      else if (char === '6') formula += '₆';
      else if (char === '7') formula += '₇';
      else if (char === '8') formula += '₈';
      else if (char === '9') formula += '₉';
      else if (char === '0') formula += '₀';
    }
  }

  return formula;
}

function processReaction (str) {
  const charArr = str.replace(/R\{|}$/g, '').split('');
  let reaction = '';
  let formula = false;
  let superstring = false;

  for (const char of charArr) {
    if (char === ' ') {
      formula = false;
      superstring = false;
      reaction += char;
    } else if (char === '^') {
      superstring = true;
    } else if (/[0-9+\-]/.test(char)) {
      if (!formula) reaction += char;
      else if (superstring) {
        if (char === '1') reaction += '¹';
        else if (char === '2') reaction += '²';
        else if (char === '3') reaction += '³';
        else if (char === '4') reaction += '⁴';
        else if (char === '5') reaction += '⁵';
        else if (char === '6') reaction += '⁶';
        else if (char === '7') reaction += '⁷';
        else if (char === '8') reaction += '⁸';
        else if (char === '9') reaction += '⁹';
        else if (char === '0') reaction += '⁰';
        else if (char === '+') reaction += '⁺';
        else if (char === '-') reaction += '⁻';
      } else {
        if (char === '1') reaction += '₁';
        else if (char === '2') reaction += '₂';
        else if (char === '3') reaction += '₃';
        else if (char === '4') reaction += '₄';
        else if (char === '5') reaction += '₅';
        else if (char === '6') reaction += '₆';
        else if (char === '7') reaction += '₇';
        else if (char === '8') reaction += '₈';
        else if (char === '9') reaction += '₉';
        else if (char === '0') reaction += '₀';
      }
    } else {
      formula = true;
      reaction += char;
    }
  }

  return reaction;
}

function processChain (str) {
  const charArr = str.replace(/CHAIN\{|}$/g, '').split('');
  let chain = '';
  let formula = false;
  let superstring = false;

  for (const char of charArr) {
    if (char === ' ') {
      formula = false;
      superstring = false;
      chain += char;
    } else if (char === '^') {
      superstring = true;
    } else if (/[0-9+\-]/.test(char)) {
      if (!formula) chain += char;
      else if (superstring) {
        if (char === '1') chain += '¹';
        else if (char === '2') chain += '²';
        else if (char === '3') chain += '³';
        else if (char === '4') chain += '⁴';
        else if (char === '5') chain += '⁵';
        else if (char === '6') chain += '⁶';
        else if (char === '7') chain += '⁷';
        else if (char === '8') chain += '⁸';
        else if (char === '9') chain += '⁹';
        else if (char === '0') chain += '⁰';
        else if (char === '+') chain += '⁺';
        else if (char === '-') chain += '⁻';
      } else {
        if (char === '1') chain += '₁';
        else if (char === '2') chain += '₂';
        else if (char === '3') chain += '₃';
        else if (char === '4') chain += '₄';
        else if (char === '5') chain += '₅';
        else if (char === '6') chain += '₆';
        else if (char === '7') chain += '₇';
        else if (char === '8') chain += '₈';
        else if (char === '9') chain += '₉';
        else if (char === '0') chain += '₀';
      }
    } else {
      formula = true;
      chain += char;
    }
  }

  return chain;
}

exports.processChemistry = processChemistry;
