<template>
  <div v-if="dataLoaded">
    <p v-if="contentType === 'text'"
       :class="mod === 'chain' ? 'chain' : ''"
       class="whitespace-pre-line">{{ content }}</p>
    <div v-else-if="contentType === 'mixed'"
       class="whitespace-pre-line">
      <div v-for="block in processedContent" :key="block.key">
        <p v-if="block.contentType === 'text'"
           class="whitespace-pre-line">{{ block.content }}</p>
        <img v-if="block.contentType === 'image'"
             :src="block.content" />
      </div>
    </div>
    <img v-else-if="contentType === 'image'" :src="sources.main"
         :onerror="`this.onerror = null; this.src='${sources.fallback}'`"
         class="mx-auto my-4"
         style="max-height: 35vh;">
    <div v-else-if="contentType === 'table'"
         class="overflow-x-auto"
         style="max-width: 100%;">
      <table class="mx-auto my-4 text-center text-sm bg-white">
        <tr>
          <th v-for="cell in table[0].content"
              :key="cell.key"
              class="p-2 border bg-gray-200">{{ cell.content }}</th>
        </tr>
        <tr v-for="row in table.slice(1)" :key="row.key">
          <td v-for="cell in row.content"
              :key="cell.key"
              class="p-2 border">{{ cell.content }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import axiosInstance from '@/services/axios';
import { processChemistry } from '@/assets/formulasProcessing';

export default {
  props: {
    content: String,
    mod: String,
  },
  data () {
    return {
      baseLink: 'https://storage.yandexcloud.net/biology/task_imgs/',
      processedContent: [],
      table: [],
      dataLoaded: false,
    };
  },
  computed: {
    contentType () {
      if (/IMG_SKELETAL/.test(this.content)) return 'mixed';
      if (/\d{1,3}_IMG_/.test(this.content)) return 'image';
      if (/\d{1,3}_TAB_/.test(this.content)) return 'table';
      return 'text';
    },
    sources () {
      if (this.contentType !== 'image') return undefined;
      return {
        main: this.baseLink + this.content.replace('IMG_', '') + '.jpg',
        fallback: this.baseLink + this.content.replace('IMG_', '') + '.png',
      };
    },
  },
  created () {
    if (this.contentType === 'table') {
      return axiosInstance.get(`/api/v3/ege/table?name=${this.content}`)
          .then((response) => {
            this.table = response.data.content.split('\n')
              .map(r => ({
                content: r.split('\t').map(c => ({ content: processChemistry(c).processed, key: nanoid(8) })),
                key: nanoid(8),
              }));
            this.dataLoaded = true;
          });
    }

    if (this.contentType === 'mixed') {
      this.processedContent = this.content
          .split(/(IMG_SKELETAL\(.+?\))/)
          .map((d) => {
            if (d.startsWith('IMG_SKELETAL')) {
              const link = this.baseLink + d.replace('IMG_SKELETAL(', '').replace(')', '') + '.png';
              return { content: link, contentType: 'image', key: nanoid(8) };
            } else return { content: d, contentType: 'text', key: nanoid(8) };
          })
    }

    this.dataLoaded = true;
    return true;
  },
  methods: {

  },
}
</script>

<style scoped>
.chain {
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem 0;
}
</style>
